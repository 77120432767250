<template>
  <div style="height: 100%">

    <!-- 卡片视图 -->
    <el-card style="height: 100%;overflow:auto;">
       <!-- 顶部面包屑导航 -->
    <breadcrumb-nav>
      <template v-slot:firstMenu>财务中心</template>
      <template v-slot:secondMenu>发票管理</template>
    </breadcrumb-nav>
      <div class="titleBox">查看详情</div>
      <div style="display: flex;justify-content: space-between;margin-bottom:20px">
        <div>结算量合计：{{invoiceInformation.reconciliation.totalWeight}}吨</div>
        <div>结算金额总计：{{invoiceInformation.reconciliation.totalAmount}}元</div>
        <div>剩余未开票数量：{{invoiceInformation.reconciliation.residueQuantity}}吨</div>
        <div>剩余未开票金额：{{invoiceInformation.reconciliation.residueMoney}}元</div>
        <div>已开票金额：{{invoiceInformation.reconciliation.invoicedAmount}}元</div>
      </div>
      <div style="float: right;">
        <!-- <el-button type="primary" @click="confirm(titleData.reconciliationId)" v-if="type==1&&titleData.invoiceStatus==1" style="marginRight:10px">确认开票</el-button> -->
        <invoice-look v-if="type==1" :role-id="titleData"  :trad-name="titleData.tradName" :buttonType="2"/>
        <invoice-edit v-if="type==2" :role-id="titleData" :trad-name="titleData.tradName" :buttonType="2"  @role-list="updateRoleLists"/>
        <!-- <invoice-cancel v-if="titleData.invoiceStatus==1&&type==2" :role-id="titleData.reconciliationId" :trad-name="titleData.tradName" :buttonType="2"  @role-list="updateRoleLists"/> -->
        <invoice-make v-if="type==2" :role-id="titleData.reconciliationId" :trad-name="titleData.tradName" :buttonType="2"  @role-lists="updateRoleTaredList" />
        <!-- <el-button type="primary" @click="ArrangePlan()">导出</el-button> -->
      </div>
      <!-- <div style="marginTop: 20px" v-if="titleData.invoiceStatus!==0" >
        <el-descriptions class="margin-top" title="发票申请信息" :column="2" :size="size" border>
          <el-descriptions-item>
            <template slot="label">
              名称
            </template>
            {{titleData.invoiceTitle}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              纳税人识别号
            </template>
            {{titleData.identificationNumber}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              地址
            </template>
            {{titleData.invoiceAddress}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">

              电话
            </template>
            {{titleData.invoicePhone}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              开户行
            </template>
            {{titleData.bankOfDeposit}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              账号
            </template>
            {{titleData.accountNumber}}
          </el-descriptions-item>
        </el-descriptions>
      </div> -->
      <div class="radioBox">
          <el-radio-group v-model="radio1" @change="agreeChange" fill="#fff">
              <el-radio-button label="0" :class="radio1==0?'activeRadioButtonBox':'radioButtonBox'">申请中</el-radio-button>
              <el-radio-button label="1" :class="radio1==1?'activeRadioButtonBox':'radioButtonBox'">已通过</el-radio-button>
              <el-radio-button label="2" :class="radio1==2?'activeRadioButtonBox':'radioButtonBox'">未通过</el-radio-button>
          </el-radio-group>
      </div>
      <div>
        <el-table :data="invoiceInformation.invoice" style="width: 100%" border>
          <el-table-column
            prop="invoiceCreateDate"
            label="申请时间">
          </el-table-column>
          <el-table-column
            prop="invoiceAmount"
            label="开票金额">
          </el-table-column>
          <el-table-column
            prop="invoiceQuantity"
            label="开票数量">
          </el-table-column>
          <el-table-column
            prop="invoiceUser"
            label="申请人"
            v-if="type==2"
            >
          </el-table-column>
          <el-table-column key="14" label="操作" width="200px" v-if="type==2||type==1&&radio1!=2">
            <template slot-scope="scope">
              <!-- <el-button type="text" @click="pass(scope.row,scope.row.reconciliationId)" v-if="type==1">通过</el-button> -->
              <invoice-trade-make v-if="type==1&&radio1==0" :role-id="titleData.reconciliationId" :role-index="scope.row" :buttonType="2"  @role-lists="updateRoleTaredList"/>
              <el-button type="text" @click="reject(scope.row,scope.row.invoiceId)" v-if="type==1&&radio1==0">拒绝</el-button>
              <el-button type="text" @click="cancel(scope.row.invoiceId)" v-if="type==2&&radio1==0">取消申请</el-button>
              <el-button type="text" @click="look(scope.row.invoicePath)" v-if="type==2&&radio1==1||type==1&&radio1==1">查看发票</el-button>
              <invoice-anew-make v-if="type==2&&radio1==2" :role-id="titleData.reconciliationId" :role-index="scope.row" :buttonType="2"  @role-lists="updateRoleTaredList"/>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- <div style="marginTop: 20px" > -->
        <!-- <div style="marginBottom: 20px;fontSize: 16px;fontWeight: 700"><span >请上传已开票的发票图片</span></div>
        <div>
          <el-upload
                      :action="uploadUrl"
                      :on-preview="handleImgPreview"
                      :on-remove="handleImgRemove"
                      list-type="picture-card"
                      :headers="imgUploadHeaders"
                      :limit="9"
                      :class="{'demo-httpRequestImg':httpRequestImg}"
                      :on-success="handleImgUploadSuccess">
                <i class="el-icon-plus"></i>
              </el-upload>
        </div> -->
        <!-- <el-dialog
            title="图片预览"
            :visible.sync="previewDialogVisible"
            width="50%" append-to-body>
        <img width="100%" height="100%" :src="previewImgUrl" class="previewImg">
        </el-dialog> -->
      <!-- </div> -->
      <!-- <div style="marginTop: 20px" v-if="titleData.invoiceStatus==2">
        <div style="marginBottom: 20px;fontSize: 16px;fontWeight: 700"><span >{{ type==1?'已开票发票信息':'请仔细核对发票信息，如有问题，请及时与贸易商联系' }}</span></div>
        <div class="imageBoxs">
          <div v-for="item in invoicePath" style="width: 100px;height: 100px;margin-bottom: 15px;margin-right: 15px;" @click="lookImage(item)">
            <img style="width: 100%;height: 100%;" :src=" api.imageUrl+'/trade/images/'+item" alt="" >
          </div>
        </div>
        
      </div> -->
      <el-dialog
            title="图片预览"
            :visible.sync="previewDialogVisible"
            width="50%" append-to-body>
        <img :src="previewImgUrl" class="previewImg">
        </el-dialog>
      <div style="marginTop: 20px">
        <el-descriptions class="margin-top" title="对账单信息" :column="2" :size="size" border>
          <el-descriptions-item>
            <template slot="label">
              {{type==1?'购液企业':'贸易企业'}}
            </template>
            {{type==1?titleData.emptionName:titleData.tardName}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              {{type==1?'购液企业余额':'贸易商余额'}}
            </template>
            {{titleData.balance}}元
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              对账计划日期
            </template>
           {{titleData.startDate}} 至 {{titleData.endDate}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              对账单标题
            </template>
            {{titleData.reconciliationName}}
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="numberBox">
        <div class="settlement">
          <div class="settlement_title">结算量合计</div>
          <div class="settlement_number"><span style="fontSize: 36px;fontWeight: bold;">{{titleData.totalWeight}}</span><span style="fontSize: 20px;fontWeight: bold;"> 吨</span></div>
        </div>
        <div class="settlement">
          <div class="settlement_title">总车数</div>
          <div class="settlement_number"><span style="fontSize: 36px;fontWeight: bold;">{{titleData.countCar}}</span><span style="fontSize: 20px;fontWeight: bold;"> 车</span></div>
        </div>
        <div class="settlement">
          <div class="settlement_title">总计</div>
          <div class="settlement_number"><span style="fontSize: 36px;fontWeight: bold;">￥{{titleData.totalAmount}}</span><span style="fontSize: 20px;fontWeight: bold;"></span></div>
        </div>
      </div>
      <!-- table表格区域 -->
      <unapplied-page-list :reconciliation-list="goodsList" @reconciliation-list='updateRoleList'/>
      <div style="float: right;">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryInfo.pageNo"
            :page-sizes="[10, 20, 30, 50]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </el-card>
    <el-dialog
        title="图片预览"
        :visible.sync="previewDialogVisible"
        width="50%" append-to-body>
        <template v-if="previewDialogVisibleText == false">
          <img v-for="item in urlList"  :src="item" class="previewImg" @click="BlowUpImage(item)">
        </template>
      <span v-if="previewDialogVisibleText">暂无图片</span>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisibleOne" width="30%" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
  import BreadcrumbNav from "../../../common/BreadcrumbNav";
  import UnappliedPageList from './UnappliedPageList';
  import { TradeDetailsRequest,orderRequests,orderRequest,ConfirmRequest,uploadRequest,uploadEmpRequest,uploadOneRequest,getLiquidList,getTradeList,cancelInvoice,downEdit } from "network/financial";
  import InvoiceEdit from './InvoiceEdit.vue';
  import InvoiceCancel from './InvoiceCancel.vue';
  import InvoiceMake from './InvoiceMake.vue';
  import InvoiceAnewMake from './InvoiceAnewMake.vue';
  import InvoiceLook from './InvoiceLook.vue';
  import InvoiceTradeMake from './InvoiceTradeMake.vue';

  export default {
    name: "List",
    components: {
      BreadcrumbNav,
      UnappliedPageList,
        InvoiceEdit,
        InvoiceCancel,
        InvoiceMake,
        InvoiceLook,
        InvoiceTradeMake,
        InvoiceAnewMake
    },
    data() {
      return {
         type: localStorage.getItem('type'),
         size: '',
         httpRequestImg:false,
         previewDialogVisibleText: false,
         previewImgUrl: '',
         urlList: [],
        queryInfo: {
          query: "",
          pageNo: 1,
          pageSize: 10
        },
        formInline: {
          user: '',
          region: ''
        },
        goodsList: [],
        total: 0,
        titleData: {},
        invoicePath: [],
        radio: '1',
        radio1: '0',
        uploadUrl: this.api.imageUrl + '/trade/purchaseorder/uploadImg',
        imgUploadHeaders: { // 上传图片控件的header
          Authorization: localStorage.getItem('token')
        },
        imageLists: [],
        previewImgUrl: '',
        previewDialogVisible: false,
        invoiceInformation: {},
        dialogVisibleOne: false
      }
    },
    created() {
      this.getGoodsList();
      this.getLookType();
      this.getInvoiceList()
    },
    methods: {

      BlowUpImage(url){
        this.dialogImageUrl=url
        this.dialogVisibleOne = true
      },

      //取消申请发票
      cancel(id){
        cancelInvoice({
          invoiceId: id
        }).then(res=>{
          console.log(res);
          if(res.data.code !== 200){
            return this.alertMessage(res.data.msg,'error')
          }
          this.alertMessage(res.data.msg,'success')
          this.getInvoiceList()
        })
      },

      reject(scope,scopeId){
        this.$confirm('拒绝后需要购液企业再次发起申请，您还要继续吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          downEdit({
            invoiceId:scopeId
          }).then(res=>{
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage(result.msg, 'error');
          }
          this.alertMessage(res.data.msg,'success')
          this.getInvoiceList()
        })
        }).catch(() => {
          this.alertMessage('已取消删除','info');
        });
        
      },

      look(url){
        console.log(url.split(','));
      this.previewDialogVisible = true;
        if(url==''){
         this.previewDialogVisibleText = true
        }
        else{
          let arr = url.split(',')
          this.urlList = arr.map((item)=> this.api.imageUrl+'/trade/images/'+item)
        }
    },
      
      getLookType(){
        orderRequests({
          id: this.$route.query.id
        }).then(res=>{
          console.log(res);
        })
      },
      getGoodsList() {
        this.titleData = this.$route.query.scope
        this.invoicePath = this.$route.query.scope.invoicePath==null?[]: this.$route.query.scope.invoicePath.split(',')
        this.radio = this.$route.query.radio
        if(this.type==1){
        TradeDetailsRequest({
          ...this.queryInfo,
          id: this.$route.query.id
        }).then(res => {
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage(result.msg, 'error');
          }
          this.goodsList = result.data.list;
          this.total = result.data.total;
        });
        }
        if(this.type==2){
          orderRequest({
          ...this.queryInfo,
          id: this.$route.query.id
        }).then(res => {
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage(result.msg, 'error');
          }
          this.goodsList = result.data.list;
          this.total = result.data.total;
        });
        }
      },

      ArrangePlan(){
        if(this.type==1){
          uploadRequest({
            reconciliationId: this.$route.query.id,
            name: this.titleData.emptionName+'开票详情',
            invoiceName:this.titleData.invoiceTitle,
            identificationNumber: this.titleData.identificationNumber,
            address: this.titleData.invoiceAddress,
            phone: this.titleData.invoicePhone,
            openingBank: this.titleData.bankOfDeposit,
            accountNumber: this.titleData.accountNumber,
            liquidPurchasing: this.titleData.emptionName,
            balance: this.titleData.balance,
            reconciliationPlan: this.titleData.startDate +','+this.titleData.endDate,
            statementHeading: this.titleData.reconciliationName,
          }).then(res=>{
            var content = res.headers['content-disposition'];
            var name = content && content.split(';')[1].split('filename=')[1];
            var fileName = decodeURIComponent(name)
            this.downloadCallback(res, fileName);
          })
        }
        if(this.type==2&&this.titleData.invoiceStatus==0){
          // if(this.titleData.invoiceStatus==1){
            uploadEmpRequest({
              reconciliationId: this.$route.query.id,
              name: this.titleData.tardName+'开票详情',
              invoiceName:this.titleData.invoiceTitle,
              identificationNumber: this.titleData.identificationNumber,
              address: this.titleData.invoiceAddress,
              phone: this.titleData.invoicePhone,
              openingBank: this.titleData.bankOfDeposit,
              accountNumber: this.titleData.accountNumber,
              liquidPurchasing: this.titleData.tardName,
              balance: this.titleData.balance,
              reconciliationPlan: this.titleData.startDate +','+this.titleData.endDate,
              statementHeading: this.titleData.reconciliationName,
              tradeCompanies: this.titleData.tardName,
              tradeBalance: this.titleData.balance
            }).then(res=>{
              var content = res.headers['content-disposition'];
              var name = content && content.split(';')[1].split('filename=')[1];
              var fileName = decodeURIComponent(name)
              this.downloadCallback(res, fileName);
            })
        }
        if(this.type==2&&this.titleData.invoiceStatus!==0){           
          uploadOneRequest({
            reconciliationId: this.$route.query.id,
            name: this.titleData.tardName+'开票详情',
            invoiceName:this.titleData.invoiceTitle,
            identificationNumber: this.titleData.identificationNumber,
            address: this.titleData.invoiceAddress,
            phone: this.titleData.invoicePhone,
            openingBank: this.titleData.bankOfDeposit,
            accountNumber: this.titleData.accountNumber,
            liquidPurchasing: this.titleData.tardName,
            balance: this.titleData.balance,
            reconciliationPlan: this.titleData.startDate +','+this.titleData.endDate,
            statementHeading: this.titleData.reconciliationName,
          }).then(res=>{
            var content = res.headers['content-disposition'];
            var name = content && content.split(';')[1].split('filename=')[1];
            var fileName = decodeURIComponent(name)
            this.downloadCallback(res, fileName);
          })
        }
      },

        //生成下载文件
    downloadCallback(res, fileName){
      const content = res.data;
      const blob = new Blob([content]);
      if ("download" in document.createElement("a")) {
        // 非IE下载
        const elink = document.createElement("a");
        elink.download = fileName;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileName);
      }
    },

        lookImage(item){
          this.previewImgUrl = this.api.imageUrl+'/trade/images/'+ item;
          this.previewDialogVisible = true;
        },
        // 处理图片预览效果
        handleImgPreview(file) {
          this.previewImgUrl = file.url
          this.previewDialogVisible = true;
        },
        // 处理移除图片的操作
        handleImgRemove(file) {
           // let reg2 = new RegExp(",");
           let removeUrl = file.url;
          let index = this.imageLists.findIndex(item => {
            return item.url === removeUrl;
          });
          this.imageLists.splice(index, 1);
          this.httpRequestImg = this.imageLists.length>=9
        },
        // 监听图片上传成功
        handleImgUploadSuccess(response) {
          let reg2 = new RegExp(",");
          let imageUrl = response.data.replace(reg2,'')
          this.imageLists.push({
            name: '',
            url: this.api.imageUrl+ '/trade/images/'+ imageUrl
          })
          this.httpRequestImg = this.imageLists.length>=9
        },

      // 每页显示的数据条数发送变化
      handleSizeChange(newSize) {
        this.queryInfo.pageNo = 1
        this.queryInfo.pageSize = newSize;
        this.getGoodsList();
      },

      // 当前页码发生变化
      handleCurrentChange(newPage) {
        this.queryInfo.pageNo = newPage;
        this.getGoodsList();
      },
      // 监听子组件中发出的事件，重新获取角色列表
      updateRoleList(){
        // this.getRolesList();
      },
      updateRoleTaredList(){
        this.getInvoiceList()
      },
      updateRoleLists(){
        this.$router.push('/pages/biz/gas_factory/finance/bill').catch(() => true)
      },
      // 确认开票
      confirm(id){
        if(this.imageLists.length==0){
          this.alertMessage('请先上传发票照片', 'error');
        }else{
          let newImage = []
          for(let i = 0; i<this.imageLists.length;i++){
            newImage.push(this.imageLists[i].url.replace(new RegExp(this.api.imageUrl+"/trade/images/",""),""))
          }
        ConfirmRequest({
                id: id,
                invoicePath: newImage.toString()
              }).then(res=>{
                let result = res.data;
                if (result.code !== 200) {
                  return this.alertMessage(result.msg, 'error');
                }
                this.alertMessage('开票成功', 'success');
                this.imageLists = []
                this.$router.push('/pages/biz/gas_factory/finance/bill').catch(() => true)
              })
      }
    },

    agreeChange() {
          this.getInvoiceList()
        },
    getInvoiceList(){
      let type = localStorage.getItem('type')
      if(type==1){
        getTradeList({
          reconciliationId:this.$route.query.id,
          invoiceStatus:this.radio1
        }).then(res=>{
          let result= res.data
          this.invoiceInformation = result.data
        })
      }if(type == 2){
        getLiquidList({
          reconciliationId:this.$route.query.id,
          invoiceStatus:this.radio1
        }).then(res=>{
          let result= res.data
          this.invoiceInformation = result.data
        })
      }
    }
    }
  }
</script>

<style scoped>
.titleBox{
  width: 100%;
  height: 46px;
  font-size: 20px;
  font-weight: normal;
  color: #0a0b1a;

}
.textBox{
  width: 100%;
  height: 66px;
  font-size: 14px;
  color: #0a0b1a;
  font-weight: normal;
  line-height: 66px;

}
.numberBox{
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}
.settlement{
  width: 528px;
  height: 150px;
  background: #f5f7fa;
  border-radius: 4px;
  opacity: 1;
}
.settlement_title{
  margin-left: 20px;
  margin-top: 23px;
  font-size: 20px;
  font-weight: normal;
  color: #0a0b1a;
}
.settlement_number{
  margin-left: 20px;
  margin-top: 24px;
  color: #54555F;

}

.radioBox{
  margin-top: 25px;
  margin-bottom: 15px;
}
.buttonBox{
  display: flex;
}
.el-button--text{
  color: var(--theme_bg_color);
}
  .el-button:focus,
  .el-button:hover {
    border: 0px;
    border-color: #fff;
    color: var(--theme_bg_color);
  }
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
.el-button--primary:focus,.el-button--primary:hover {
    border-color: var(--theme_bg_color);
    color: #fff;
  }
.imageBoxs{
  display: flex;
  /* justify-content: space-between; */
}
.demo-httpRequestImg >>> .el-upload--picture-card{
    display: none !important;
  }

  .activeRadioButtonBox :hover{
  color: #fff;
}
.radioButtonBox{
  background-color: var(--theme_bg_color);
  box-shadow: 0px 0 0 0 var(--theme_bg_color) !important;
  border-radius: 4px 4px 4px 4px;
}
  .previewImg{
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  margin-right: 10px;
}
</style>
