<template>
  <div class="editDialog">
    <el-button :type="buttonType==1?'text':buttonType==2?'primary':''" @click="openModal()">申请开票</el-button>

    <!-- 修改角色的对话框 -->
    <el-dialog
            title="申请开票"
            :visible.sync="editDialogVisible"
            width="50%">
            <div style="margin-left: 40px;margin-bottom:10px">请首先核对发票申请基本信息是否准确！！！</div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm" label-position="left" style="margin-left:40px">
        <el-form-item label="剩余未开票金额:">
          <template>{{invoiceInformation.residueMoney}}元</template>
        </el-form-item>
        <el-form-item label="剩余未开票数量:">
          <template>{{invoiceInformation.residueQuantity}}吨</template>
        </el-form-item>
        <el-form-item label="开票金额:" prop="address">
          <el-input v-model="ruleForm.address" placeholder="请输入本次开票金额" ></el-input>
        </el-form-item>
        <el-form-item label="开票数量:" prop="photo">
          <el-input v-model="ruleForm.photo" placeholder="请输入本次开票数量" ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editRole">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { systemInvoice,getTradeList,getLiquidList } from 'network/financial'

  export default {
    name: "InvoiceMake",
    props: {
      roleId: {
        default() {
          return []
        }
      },
      tradName: {
        type: String,
        default(){
          return []
        }
      },
      tradData:{
        type: Object,
        default(){
          return {}
        }
      },
      buttonType : {
        type: Number,
        default (){
          return []
        }
      }
    },
    data() {
      return {
        editDialogVisible: false,
        invoiceInformation:{},
        ruleForm: {
          invoiceName: '',
          IdentificationCode: '',
          address: '',
          photo: '',
          openingBank: '',
          accountNumber: '',
        },
        rules: {
          address: [
            {required: true, message: '请输入开票金额', trigger: 'blur'},
          ],
          photo: [
            {required: true, message: '请输入开票数量', trigger: 'blur'},
          ],
        },
      }
    },
    methods: {

      openModal(){
       this.editDialogVisible=true
       let type = localStorage.getItem('type')
      if(type==1){
        getTradeList({
          reconciliationId:this.roleId,
          invoiceStatus:0
        }).then(res=>{
          console.log(res);
          let result= res.data
          this.invoiceInformation = result.data.reconciliation

        })
      }if(type == 2){
        getLiquidList({
          reconciliationId:this.roleId,
          invoiceStatus:0
        }).then(res=>{
          console.log(res);
          let result= res.data
          this.invoiceInformation = result.data.reconciliation

        })
      }
      },

      // 监听编辑角色的弹框的关闭事件
      editDialogClose() {
        this.$refs.ruleForm.resetFields();
      },

      // 点击编辑角色弹框的“确认”按钮提交
      editRole() {
        this.$refs.ruleForm.validate(valid => {
         if (valid) {
              systemInvoice({
                reconciliationId: this.roleId,
                residueMoney: this.invoiceInformation.residueMoney,
                residueQuantity: this.invoiceInformation.residueQuantity,
                invoiceAmount: this.ruleForm.address,
                invoiceQuantity: this.ruleForm.photo,
              }).then(res=>{
                let result = res.data;
                if (result.code !== 200) {
                  return this.alertMessage(result.msg, 'error');
                }
                this.alertMessage('申请成功', 'success');
                // 隐藏弹窗
                this.editDialogVisible=false
                this.$refs.ruleForm.resetFields();
                this.$emit('role-lists');
              })
              
            } else {
              console.log('error submit!!');
              return false;
            }
        })
      }
    }
  }
</script>

<style scoped>
.editDialog {
  display: inline-block;
  margin-right: 5px;
}
.el-button--text{
  color: var(--theme_bg_color);
}
  .el-button:focus,
  .el-button:hover {
    border: 0px;
    border-color: #fff;
    color: var(--theme_bg_color);
  }
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
.el-button--primary:focus,.el-button--primary:hover {
    border-color: var(--theme_bg_color);
    color: #fff;
  }
</style>
