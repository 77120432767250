<!--
 * @Description: 
 * @Author: 
 * @Date: 2023-03-02 15:41:46
 * @LastEditors: koushaoping 1719422019@qq.com
 * @LastEditTime: 2024-03-06 18:00:21
-->
<template>
  <div class="editDialog">
    <el-button :type="buttonType==1?'text':buttonType==2?'primary':''" @click="openModal()">发票申请信息</el-button>

    <!-- 修改角色的对话框 -->
    <el-dialog
            title="发票申请信息"
            :visible.sync="editDialogVisible"
            width="50%">
      <el-form :model="ruleForm" ref="ruleForm" label-width="150px" class="demo-ruleForm">
        <el-form-item label="贸易商名称" prop="name">
             <template>{{tradName}}</template>
        </el-form-item>
        <el-form-item label="名称" prop="invoiceName">
          <template>{{ruleForm.invoiceName}}</template>
        </el-form-item>
        <el-form-item label="纳税人识别号" prop="IdentificationCode">
          <template>{{ruleForm.IdentificationCode}}</template>
        </el-form-item>
        <el-form-item label="地址" prop="address">
          <template>{{ruleForm.address}}</template>
        </el-form-item>
        <el-form-item label="电话" prop="photo">
          <template>{{ruleForm.photo}}</template>
        </el-form-item>
        <el-form-item label="开户行" prop="openingBank">
          <template>{{ruleForm.openingBank}}</template>
        </el-form-item>
        <el-form-item label="账号" prop="accountNumber">
          <template>{{ruleForm.accountNumber}}</template>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">关  闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import { EmptionRequest,InvoiceInfoRequests } from 'network/financial'

  export default {
    name: "InvoiceLook",
    props: {
      roleId: {
        default() {
          return []
        }
      },
      roleIds: {
        default() {
          return ''
        }
      },
      tradName: {
        type: String,
        default(){
          return []
        }
      },
      buttonType : {
        type: Number,
        default (){
          return []
        }
      }
    },
    data() {
      var checkPhone = (rule, value, callback) => {
        let regPone = null
        let mobile = /^((13|14|15|17|18)[0-9]{1}\d{8})$/ // 最新16手机正则
        let tel = /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/ // 座机
        if (value !== null && value.charAt(0) === '0') { // charAt查找第一个字符方法，用来判断输入的是座机还是手机号
          regPone = tel
        } else if (value !== null && value.charAt(0) !== '0') {
          regPone = mobile
        }
        if (regPone === null) {
          return callback(
            new Error('请输入电话')
          )
        } else if (!regPone.test(value)) {
          return callback(
            new Error("请输入正确的电话格式,其中座机格式'区号-座机号码'")
          )
        } else {
          callback()
        }
      }
      return {
        editDialogVisible: false,
        ruleForm: {
          invoiceName: '',
          IdentificationCode: '',
          address: '',
          photo: '',
          openingBank: '',
          accountNumber: '',
        },
      }
    },
    methods: {

      openModal(){
       this.editDialogVisible=true
       InvoiceInfoRequests({
        id: this.roleId.emptionId,
        reconciliationId: this.roleId.reconciliationId
       }).then(res=>{
        let result = res.data
        this.ruleForm = {
          invoiceName: result.data.invoiceTitle,
          IdentificationCode: result.data.identificationNumber,
          address: result.data.invoiceAddress,
          photo: result.data.invoicePhone,
          openingBank: result.data.bankOfDeposit,
          accountNumber: result.data.accountNumber,
        }
       })
      },

      // 监听编辑角色的弹框的关闭事件
      editDialogClose() {
        this.$refs.ruleForm.resetFields();
      },
    }
  }
</script>

<style scoped>
.editDialog {
  display: inline-block;
  margin-right: 5px;
}
.el-button--text{
  color: var(--theme_bg_color);
}
  .el-button:focus,
  .el-button:hover {
    border: 0px;
    border-color: #fff;
    color: var(--theme_bg_color);
  }
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
.el-button--primary:focus,.el-button--primary:hover {
    border-color: var(--theme_bg_color);
    color: #fff;
  }
</style>
