<!--
 * @Description: 
 * @Author: 
 * @Date: 2023-03-01 14:51:56
 * @LastEditors: koushaoping 1719422019@qq.com
 * @LastEditTime: 2024-03-01 11:15:21
-->
<template>
  <el-table  :data="reconciliationList" border :header-cell-style="{color: '#848484', fontSize: '14px', height:'22px',  background: '#f7f7f7'}" :cell-style="{color: '#0a0b1a'}">
    <el-table-column label="计划日期" prop="planTime" width="170px">
      <template slot-scope="scope">
        {{scope.row.planTime }}
      </template>
    </el-table-column>
    <el-table-column prop="sourceName" label="液源" ></el-table-column>
    <el-table-column prop="deliveryType" label="配送方式" >
      <template slot-scope="scope">
        <span>{{scope.row.deliveryType==1?"自提":scope.row.deliveryType==2?"配送":""}}</span>
      </template>
    </el-table-column>
    <el-table-column prop="tractorNumber" label="车辆信息" >
      <template slot-scope="scope">
        <span>{{scope.row.tractorNumber?scope.row.tractorNumber.slice(0,-5):"--"}}</span>
      </template>
    </el-table-column>
    <el-table-column prop="shipmentWeight" label="装车量(吨)" ></el-table-column>
    <el-table-column prop="unloadWeight" label="卸车量(吨)" ></el-table-column>
    <el-table-column prop="settlementVolume" label="结算量(吨)" ></el-table-column>
    <el-table-column prop="settlementUnitPrice" label="结算单价(元)" ></el-table-column>
    <el-table-column prop="settlementAmount" label="结算总金额(元)" ></el-table-column>
    <el-table-column prop="amount" label="进款金额(元)" ></el-table-column>
    <el-table-column prop="orderNumber" label="订单编号" ></el-table-column>
  </el-table>
</template>
<script>

  export default {
    name: "UnappliedPageList",
    props: {
      reconciliationList: {
        type: Array,
        default() {
          return []
        }
      }
    },
    components: {
        
      
    },
    methods: {
      // 监听子组件中发出的事件，重新获取角色列表
      updateRoleList() {
        this.$emit('role-list');
      }
    }
  }
</script>

<style scoped>

</style>
